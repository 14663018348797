import React, {useEffect, useState} from 'react';
import {graphql} from 'gatsby';
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import InnerPage from "../../components/innerPage";
import useSWR from "swr";
import {graphqlFetcher} from "../../modules/swrFetchers";
import LoadingIndicator from "../../components/loadingIndicator";
import ErrorBlock from "../../components/errorBlock";
import parse from "html-react-parser";

/**
 * ASCP page
 * @returns {JSX.Element}
 * @private
 */
const ASCP = () => {
    const {t} = useTranslation();
    const {language, languages, originalPath} = useI18next();


    const [pageContent, setPageContent] = useState();
    const [isError, setIsError] = useState(false);
    const pageGraphQL = `
    query GetPostsEdges($categoryName: String!) {
      posts(where: {categoryName: $categoryName}) {
        edges {
          node {
            content(format: RENDERED)
          }
        }
      }
    }
    `
    const pageGraphQLVar = {
        categoryName: `courses-ascp-${language}`,
    }
    const { data, error } = useSWR([pageGraphQL, pageGraphQLVar], graphqlFetcher)

    const breadcrumbs = [
        {
            title: t('courses'),
            url: "/courses"
        },
        {
            title: t('ascp')
        }
    ]


    useEffect(() => {
        if (data?.posts?.edges[0]?.node?.content) {
            setPageContent(data.posts.edges[0].node.content)
            setIsError(false);
        }
        else {
            if (data) {
                setIsError(true)
            }
        }

    }, [data])

    return (
        <Layout>
            <SEO title={t('ascp')}/>
            <InnerPage
                breadcrumbs={breadcrumbs}
                pageTitle={t('ascp')}
            >
                {!data && !error && (
                    <>
                        <LoadingIndicator />
                    </>
                )}

                {(error || isError) && (
                    <>
                        <ErrorBlock />
                    </>
                )}

                {pageContent && (
                    <>
                        {parse(pageContent)}
                    </>
                )}
            </InnerPage>
        </Layout>
    );
};

export default ASCP;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;